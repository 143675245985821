main {
    width: 100%;
    height: 100%;
}

/**-------------
 Map
--------------*/
#map {
    position: fixed;
    width:100%;
    height:100%;
}

/* .active-area {
    position: absolute;
    height: 100%;
    width: calc(100% - clamp(420px, 40vw, 500px) - clamp(25px, 2.5vw, 50px));
    right: 0;
} */

.leaflet-bottom .leaflet-control-zoom {
    margin: var(--window-margin);
    z-index: 999;
}

.leaflet-popup {
    z-index: 999;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
    background-color: var(--text-color);
}

.leaflet-popup-content {
    color: var(--primary-color);
    font-weight: 400;
    font-size: 0.75rem;
}


/**-------------
 Card
---------------*/

.card {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 20;
    width: clamp(420px, 40vw, 500px);
    height: calc(100% - var(--window-margin) * 2);
    margin: var(--window-margin);
    padding: 2rem;
    border-radius: 25px;
    background-color: var(--primary-color);
    box-shadow: 0px 0px 15px 6px rgba(0,0,0,0.8);
}

.card h2 {
    color: var(--text-color);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.1rem;
    margin: 0 0 1rem 0;
    width: 100%;
}


/**-------------
 Film
---------------*/

.movie {
    display: flex;
    flex-wrap: wrap;
    height: calc(65% - 4rem);
}

.movie img {
    max-height: calc(100% - 2.1rem);
}

.movie .info {
    flex: 1;
    padding-left: 1.1rem;
}

.movie .title {
    font-size: clamp(0.75rem, 1.1vw, 0.9rem);
    font-weight: 500;
    text-transform: uppercase;
    color: var(--highlights-color);
}

.movie .prod, .movie .prod span, .director, .stars {
    font-size: clamp(0.75rem, 1.1vw, 0.9rem);
    font-weight: 400;
    color: #7A7A7A;
}

.movie .prod, .movie .prod span {
    padding: .5rem 0;
}

.movie .synopsis {
    font-size: clamp(0.75rem, 1.1vw, 0.9rem);
    font-weight: 300;
    line-height: clamp(1rem, 1.5vw, 1.2rem);
}

.movie .material-symbols-rounded {
    font-size: 1.1rem;
    padding-right: .5rem;
    font-weight: 400;
    color: #7A7A7A;
}

.movie .dir-container, .movie .stars-container {
    display: flex;
    flex-wrap: nowrap;
    padding: .5rem 0 0 0;
    line-height: clamp(1rem, 1.5vw, 1.2rem);
}


/**-------------------------
 Graphe
--------------------------*/

.graph {
    flex: 1;
}

.label {
    font-size: 0.7rem;
}

/* Couleurs du graphe */
.domain, .tick {
    color: var(--text-color);
}

.card-divider {
    margin: 1.5rem 0;
    width: 100%;
    border: 0;
    border-top: 1px solid var(--text-color);
}


/**-------------------------
 Nav et boutons
--------------------------*/

nav {
    display: flex;
    align-items: center;

    position: absolute;
    z-index: 999;
    right: 0px;
    height: min-content;
    margin: var(--window-margin);
}

a.button {
    padding: .7rem 2rem;
    border-radius: 30px;
    background-color: var(--tertiary-color);

    text-decoration: none;
    color: var(--text-color);
}

a.button:hover {
    background-color: var(--highlights-color);
}

.ufo-img {
    max-width: 6rem;
    max-height: 4rem;
    padding-right: 2rem;
}