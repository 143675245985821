@import "~normalize.css/normalize.css";
@import url(map.css);

* {
    padding: 0;
    margin: 0;
    color: white;
    --primary-color: #262626;
    --secondary-color: #090909;
    --tertiary-color: #11686A;
    --highlights-color: #1BAFB1;
    --text-color: #fff;
    --window-margin: clamp(25px, 2.5vw, 50px);

    font-family: 'Rubik', sans-serif;
    box-sizing : border-box;
}

body {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    background-color: var(--primary-color);
}

h1 {
    font-family: sans-serif;
    color: var(--highlights-color);
    margin: var(--window-margin) 0 var(--window-margin) 0;
    align-self: center;
    text-transform: uppercase;
}

.shapes {
    width: 100%;
    max-width: 1000px;
    flex: 1;
    display: grid;
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 2rem;
    margin: var(--window-margin);
    margin-top: 0;
    align-self: center;
}

.UfoName { 
    font-size:63px;
    font-weight: 900; 
    text-transform: uppercase;
    letter-spacing: 21px;
    fill: white;
}

.shape-btn {
    display: inline;
    cursor: pointer;
    width: calc(100vw / 3 - 2rem - var(--window-margin) * 2);
    height: 100%;
    max-height: 270px;
    min-width: 180px;
    justify-self: center;
    align-self: center;
}

.fireball {
    grid-column: 1 / span 2;
    grid-row: 1 / 1;
}

.circle {
    grid-column: 3 / span 2;
    grid-row: 1 / 1;
}

.triangle {
    grid-column: 5 / span 2;
    grid-row: 1 / 1;
}

.light {
    grid-column: 2 / span 2;
    grid-row: 2 / 2;
}

.unknown {
    grid-column: 4 / span 2;
    grid-row: 2 / 2;
}



/* ----- F I R E B A L L   U F O   S H A P E ----- */

#circle { 
    fill: #FAFF00;
}

@media (max-width: 768px) {

    body {
        justify-content: center;
    }

    h1 {
        font-size: 1.7rem;
    }

    .shapes {
        grid-template-rows: repeat(3, minmax(0, 1fr));
        grid-template-columns: repeat(4, minmax(0, 1fr));
        max-height: 600px;
    }

    .shape-btn {
        max-height: 180px;
        min-width: 160px;
    }

    .fireball {
        grid-column: 1 / span 2;
        grid-row: 1 / 1;
    }
    
    .circle {
        grid-column: 3 / span 2;
        grid-row: 1 / 1;
    }
    
    .triangle {
        grid-column: 1 / span 2;
        grid-row: 2 / 2;
    }
    
    .light {
        grid-column: 3 / span 2;
        grid-row: 2 / 2;
    }
    
    .unknown {
        grid-column: 2 / span 2;
        grid-row: 3 / 3;
    }
}